@use "locus-core" as lc;

app-root {
  // Override default light red color in order to make checked state more visible
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: lc.$locus-color-err;
  }

  // Adjust paddings for small screens to prevent layout stretch and scrollbar display
  @media (max-width: 450px) {
    mat-stepper {
      .mat-horizontal-stepper-header {
        padding: 0 12px;
      }

      .mat-stepper-horizontal-line {
        margin: 0 -6px;
        min-width: 12px;
      }
    }
  }
}

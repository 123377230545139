@import '~@angular/material/theming';

// primary color: 1976d2
$locus-blue: (
  50 : #f6fafe,
  100 : #b2d4f6,
  200 : #80b8f0,
  300 : #4095e8,
  400 : #2585e5,
  500 : #1976d2,
  600 : #1667b7,
  700 : #12579b,
  800 : #0f4880,
  900 : #0c3965,
  A100 : #ebf5ff,
  A200 : #85c2ff,
  A400 : #2690f8,
  A700 : #1a83eb,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

// secondary color f4571b
$locus-orange: (
  50 : #feebe4,
  100 : #fccdbb,
  200 : #faab8d,
  300 : #f7895f,
  400 : #f6703d,
  500 : #f4571b,
  600 : #f34f18,
  700 : #f14614,
  800 : #ef3c10,
  900 : #ec2c08,
  A100 : #ffffff,
  A200 : #ffe5e1,
  A400 : #ffb8ae,
  A700 : #ffa195,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$locus-grey: (
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

$locus-color-primary: map_get($locus-blue, 500);
$locus-color-secondary: map_get($locus-orange, 500);
$locus-color-hover: map_get($locus-grey, 100);
$locus-color-hover-darker: map_get($locus-grey, 200);
$locus-color-focus: map_get($locus-grey, 300);
$locus-color-disabled: map_get($locus-grey, 400);
$locus-color-borders: map_get($locus-grey, 300);
$locus-color-borders-hover: map_get($locus-grey, 400);
$locus-color-bg-light: map_get($locus-grey, 100);
$locus-color-icon-inactive: map_get($locus-grey, 300);
$locus-color-icon-active: map_get($locus-grey, 600);
$locus-color-err: map_get($mat-red, 700);
$locus-color-bg-err: map_get($mat-red, 100);
$locus-color-success: map_get($mat-green, 700);
$locus-color-bg-success: map_get($mat-green, 50);
$locus-color-info: map_get($mat-blue, 700);
$locus-color-bg-info: map_get($mat-blue, 50);
$locus-color-warning: map_get($mat-orange, 700);
$locus-color-bg-warning: map_get($mat-orange, 50);

$locus-color-light-grey: map_get($locus-grey, 100);
$locus-color-grey: map_get($locus-grey, 600);
$locus-color-dark-grey: map_get($locus-grey, 900);

$locus-color-gold: #D1B000;

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import "styles/roboto";
@import "styles/material-icons";
@import "styles/locus-core";
@import "styles/material-customization";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ui-primary: mat.define-palette($locus-blue);
$ui-accent: mat.define-palette($locus-orange, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ui-theme: mat.define-light-theme((
  color: (
    primary: $ui-primary,
    accent: $ui-accent,
    warn: $ui-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ui-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }



/* Global alert color styles */
.snack-bar-error, .alert-error {
  color: $locus-color-err;
  border: solid 1px $locus-color-err;
  background-color: $locus-color-bg-err;
}

.snack-bar-warning, .alert-warning {
  color: $locus-color-warning;
  border: solid 1px $locus-color-warning;
  background-color: $locus-color-bg-warning;
}

.snack-bar-success, .alert-success {
  color: $locus-color-success;
  border: solid 1px $locus-color-success;
  background-color: $locus-color-bg-success;
}

.snack-bar-info, .alert-info {
  color: $locus-color-info;
  border: solid 1px $locus-color-info;
  background-color: $locus-color-bg-info;
}

.alert {
  padding: 4px 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  mat-icon {
    margin-right: 8px;
  }
}

// Do not show spinner
// Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Firefox
input[type=number] {
  -moz-appearance: textfield;
}

.form-actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: end;
  gap: 8px;
  margin-top: 12px;
}

.list {
  display: flex;
  flex-direction: column;
  margin: 8px 4px;
  gap: 8px;

  .row {
    display: flex;

    &-icon {
      display: flex;
      align-items: center;
      width: 40px;
      max-width: 40px;
    }

    &-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      &-label {
        font-size: 0.85em;
        color: $locus-color-grey;
      }

      &-value {

      }
    }
  }
}
